<template lang="html">
  <div>
    <h2 class="animate">{{str}}</h2>
  </div>
</template>

<script>
export default {
  props: {
    str: String
  }
}
</script>

<style lang="css">
  .animate {
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    margin: auto;
    animation: load 1.2s infinite 0s ease-in-out;
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
    font-size: 20px;
    font-weight: 800;
  }
  @keyframes load {
    0% {
      opacity: 0.08;
      filter: blur(5px);
      letter-spacing: 3px;
    }
    100%{
      /* opacity: 1; */
    }
  }
</style>